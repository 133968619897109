import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'
import { LoginForm, LoginFormData, LoginFormLocalization } from '../../components/forms/LoginForm/LoginForm'
import { Layout } from '../../components/Layout'
import { useAuthContext } from '../../core/auth/AuthContext'
import { useLoginMutation } from '../../graphql/generated/auth.generated'
import { Path } from '../../static/enums/Path'

export default function (props: { localization: LoginFormLocalization }) {
  const { localization } = props

  const [login] = useLoginMutation()
  const { setToken } = useAuthContext()
  const { push } = useRouter()

  const onSubmit = useCallback(
    async (data: LoginFormData) => {
      const res = await login({
        variables: data,
      })

      const accessToken = res.data?.login!.accessToken!
      setToken(accessToken)
      push(Path.SEARCH)
    },
    [login, setToken]
  )

  return (
    <Layout>
      <LoginForm onSubmit={onSubmit} initialData={undefined} localization={localization} />
    </Layout>
  )
}

export const getStaticProps = () => {
  const localization: LoginFormLocalization = {
    labels: {
      email: 'Email',
      password: 'Password',
    },
    okButton: 'Login',
    signUp: 'Sign up',
    dontHaveAccount: 'Don’t have account?',
    forgot: 'Forgot your password?',
    login: 'Login',
  }

  return {
    props: {
      localization,
    },
  }
}
